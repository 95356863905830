// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-access-index-js": () => import("./../../../src/pages/access/index.js" /* webpackChunkName: "component---src-pages-access-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-facilities-index-js": () => import("./../../../src/pages/facilities/index.js" /* webpackChunkName: "component---src-pages-facilities-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-templates-access-details-js": () => import("./../../../src/templates/access-details.js" /* webpackChunkName: "component---src-templates-access-details-js" */),
  "component---src-templates-facility-details-js": () => import("./../../../src/templates/facility-details.js" /* webpackChunkName: "component---src-templates-facility-details-js" */),
  "component---src-templates-product-details-js": () => import("./../../../src/templates/product-details.js" /* webpackChunkName: "component---src-templates-product-details-js" */)
}

